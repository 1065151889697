$accordion-border-radius: 10px;
$boxshadow: 0 10px 30px -20px rgba(0, 0, 0, .15);

.accordionItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: $boxshadow;
  margin-bottom: 1.25rem;
  border-radius: $accordion-border-radius;
  overflow: hidden;
}

.header {
  font-family: poppinslight, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 18px;
  padding: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  .icon {
    margin-right: 4px;
  }

  &.open{
    background: #009698;
    color: #fff;
    transition: background-color 0.2s, color 0.2s;
  }

  transition: background-color 0.2s, color 0.2s;
}

.accordionContent {
  transition: height ease-out 0.2s;
  overflow: hidden;
  background: #fff;
  gap: 15px;

  &.hasHint {
    overflow: unset;
  }

  &.open{
    padding: 25px;
    border-top: solid 1px rgba(0, 0, 0, .08);
  }

  //height: 0px;
}
