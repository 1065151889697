.form {

  .page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  label {
    flex: 1;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr;
    justify-content: start;
    align-items: center;
    padding-bottom: 4px;
    gap: 15px;
    font-size: 16px;

    label.row2Input {
      margin-bottom: 0;
    }

    .labelContainer {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;

      .label {
        flex: 1;
      }

      .hintContainer {
        border-radius: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        font-size: 12px;
        line-height: normal;
        font-weight: 600;
        margin-right: 6px;
        background: #144569;
        color: #fff;

        .hint {
          display: none;
          position: absolute;
          z-index: 1;
          background: white;
          color: #2e2d2c;
          padding: 8px;
          border-radius: 10px;
          box-shadow: 2px 2px 10px -2px #c9c9c9;
          font-weight: 300;
          font-size: .9rem;
          text-align: start;
          white-space: nowrap;

          &.show {
            display: block;
          }
        }
      }

    }

    .inputContainer {
      background-color: #fff;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 38px;
      padding: 0 15px;

      &.inputCheckbox {
        width: auto;
        border: none;
        background-color: unset;

        input[type="checkbox"] {
          width: auto;
        }
      }

      &.inputAdornmentStart {
        flex-direction: row-reverse;
      }

      .inputAdornment {
        padding: 0 2px;
      }

      input {
        font-family: poppinslight, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        width: 100%;
        text-align: right;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .form{

    label{
      display: block;
    }

    .labelContainer{
      margin-bottom: 5px;
    }
  }
}
