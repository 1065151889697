@use "colors";

/* Schriftarten */

@font-face {
  font-family: 'poppinsbold';
  src: url('assets/fonts/poppins-bold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsextralight';
  src: url('assets/fonts/poppins-extralight-webfont.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;

}

@font-face {
  font-family: 'poppinslight';
  src: url('assets/fonts/poppins-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('assets/fonts/poppins-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('assets/fonts/poppins-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

html,
body {
  margin: 0;
  font-family: poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 200;
  color: #2e2d2c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  background-color: colors.$background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input[type="number"] {
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Schriftgrößen */

h1, h2{
  font-size: 36px;
  font-weight: 400;
}
