$datacontainer-border-radius: 10px;
$boxshadow: 0 10px 30px -20px rgba(0, 0, 0, .15);

.container {
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: $datacontainer-border-radius;
  box-shadow: $boxshadow;
  margin-bottom: 20px;

  .header {
    font-family: poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 28px;
    padding: 0 0 25px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    .gridLabel {
      padding: 0.5rem 0 0.5rem 0.2rem;
      border-bottom: solid 1px rgba(0,0,0, .10);
    }

    .gridValue {
      padding: 10px 15px;
      text-align: right;
      border-bottom: solid 1px rgba(0,0,0, .10);
    }

    .negativeCapital {
      background: #f07e26;
      font-weight: 600;
      color: #fff;
    }
    .positiveCapital {
      background: #87b24b;
      font-weight: 600;
      color: #fff;
    }
  }
}

.chart{
  width: 100%;
  background: #fff;
  padding: 1.563rem;
  border-radius: $datacontainer-border-radius;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, .15);
}

@media screen and (max-width: 768px) {
  .container{
    .grid{
      grid-template-columns: 1fr;

      .gridLabel{
        font-weight: 900;
        padding: 10px 0;
      }

      .gridValue{
        text-align: left;
        padding: 10px 0;
      }

      .positiveCapital{
        padding: 10px 15px;
      }

      .negativeCapital{
        padding: 10px 15px;
      }
    }
  }
}
