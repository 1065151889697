
.changeChartMode {
  input {
    margin-right: 8px;
  }
}

.chart {
  margin-top: 10px;
}
